.add-reaction {
	position: absolute;
	z-index: 100;
	top: 0.1em;
	right: -3.5em;
	padding: 0.15em;

	display: flex;
	flex-direction: row;

	background-color: var(--popup-color);
	border-radius: 0.2em;
	box-shadow: 0 0.1em 0.3em #00000080;
}
.add-reaction.root {
	left: 0;
	top: 100%;
	right: inherit;
}

button {
	font-size: 0.7em;
	padding: 0;
	border: 0;
	width: 1.5em;
	height: 1.5em;
	background-color: transparent;
	border-radius: 0.3em;
}
button:hover {
	background-color: var(--item-hover-color);
}
