.box {
	display: inline-block;
	vertical-align: top;
	position: relative;
}
.box.root {
	width: 100%;
}
.reaction-container {
	text-align: center;
}
.reaction-container.root {
	text-align: left;
}

.count {
	font-size: 0.7em;
	vertical-align: middle;
}
.content {
	cursor: pointer;

	position: relative;
	display: block;
	padding-left: 0.4em;
	padding-right: 0.4em;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	margin: 0.1em;

	background-color: var(--popup-color);
	border-radius: 0.4em;

	transition: background-color 0.1s, box-shadow 0.1s;
}
.content:hover:not(.root) {
	background-color: var(--background-color);
	box-shadow: inset 0 0 0 0.05em var(--border-color);
}

.content.root {
	border-radius: 0;
	background-color: transparent;
	margin-left: -0.3em;
}
