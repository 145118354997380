body {
	font-family: "Roboto", sans-serif;
	background: var(--background-color);
	color: var(--text-color);
}
:root {
	font-size: min(3vh, 4.75vw);
	--text-color: #dcddde;
	--light-color: #71757c;
	--name-color: #ffffff;
	--background-color: #37393f;
	--hover-color: #33353b;
	--item-hover-color: #4f545c;
	--popup-color: #2f3137;
	--border-color: #5e6064;
	--midrange-text-color: #b9bbbe;
	--input-color: #40444b;
}
