.app {
	box-shadow: 0 0 0.5rem #00000080;
	border-radius: 0.5rem;
	height: calc(100vh - 1.75rem);
	width: min(calc(100vw - 1.75rem), 100vh);

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.chat-container {
	overflow: scroll;
	height: calc(100% - 3rem);
}

.text-input {
	position: absolute;
	width: calc(100% - 3rem);
	bottom: 0.5rem;
}

.text-input input {
	width: 100%;
	background-color: var(--input-color);
	border: none;
	padding: 1rem;
	color: var(--text-color);
	font-size: 1rem;
	border-radius: 0.5rem;
	margin-left: 0.5rem;
}
.text-input input:focus {
	outline: none;
}

.logo {
	text-decoration: none;
	font-size: 0.5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	color: var(--light-color);

	position: absolute;
	right: -1.5rem;
	top: 0;
	bottom: 0;
}

.brand {
	margin-top: 0.2em;
	font-weight: bold;
	padding: 0.4em;
	padding-top: 0.3em;
	border-radius: 0.4em;
	background-image: linear-gradient(
		185deg,
		var(--item-hover-color) 20%,
		var(--item-hover-color) 130%
	);
}
.logo:hover {
	color: white;
}
.logo:hover .brand {
	text-shadow: 0 0 0.2em #000000a0;
	background-image: linear-gradient(
		185deg,
		rgba(174, 68, 191, 1) 20%,
		rgba(217, 156, 106, 1) 130%
	);
}
